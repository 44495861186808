<template>
	<div id="studentCheck">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>基础配置</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/statement' }">报表中心</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学生请假报表</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f-item u-f-jsb" style="padding-right: 15px;border-bottom: 1px solid #EEEEEE;">
					<div class="title">共{{ total }}条数据</div>
					<div class="u-f-item">
						<el-select v-model="gradeId" placeholder="请选择年级" size="small" style="width: 120px;margin-right: 10px;" @change="gradecn">
						   <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="classId" placeholder="请选择班级" size="small" style="width: 120px;margin-right: 10px;" @change="classcn1">
						    <el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
						</el-select>
						<el-input v-model="key" size="small" placeholder="请输入学生姓名"  prefix-icon="el-icon-search" style="width: 140px;margin-right: 10px;"></el-input>
						<el-button size="mini" type="primary" @click="shuaxuan">筛选</el-button>
						<el-button size="mini" type="primary" @click="teacherDerive">导出</el-button>
					</div>
				</div>
				<div style="padding:0 15px;">
					<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%;height: 73vh;">
						<el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
						<el-table-column label="姓名" align="center" prop="realname"></el-table-column>
						<el-table-column prop="grade_name" label="年级" align="center"></el-table-column>
						<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
						<el-table-column prop="start_time_text" label="开始时间" align="center"></el-table-column>
						<el-table-column prop="end_time_text" label="结束时间" align="center"></el-table-column>
						<el-table-column prop="day" label="请假天数" align="center"></el-table-column>
						<el-table-column prop="type_text" label="请假类型" align="center"></el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="13"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import Progress from 'easy-circular-progress';
import index from '@/components/common/index.vue';
export default {
	components: {
		Progress,
		index
	},
	props: {},
	data() {
		return {
			gradeList:[],
			classList:[],
			gradeId:'',
			classId:'',
			key:'',
			page: 1,
			total: 0,
			List: []
		};
	},
	mounted() {
		this.initData();
		this.getgradeList();
	},
	methods: {
		gradecn(){
			if(this.gradeId){
				this.getClassList()
				this.classId = '';
				this.page = 1;
				this.initData()
			}
		},
		shuaxuan(){
			this.page = 1;
			this.initData()
		},
		classcn1(){
			this.page = 1;
			this.initData()
		},
		// 获取年级列表
		getgradeList(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
				}
			})
		},
		// 获取班级列表
		getClassList(){
			this.$api.setting.getClassList({
				filter: JSON.stringify({'grade_id': this.gradeId})
			}).then(res=>{
				this.classList = res.data.rows;
			})
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.initData();
			console.log(`当前页: ${val}`);
		},
		initData() {
			let data = {
				page: this.page,
				limit: 13
			}
			if(this.gradeId){data.grade_id = this.gradeId}
			if(this.classId){data.class_id = this.classId}
			if(this.key){data.realname = this.key}
			this.$api.student
				.student_statement(data)
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.total = res.data.data.total;
						this.List = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		teacherDerive(){
			this.$api.student.student_derive({}).then(res=>{
				if (res.data.code == 1) {
					location.href = res.data.data.url;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		}
	}
};
</script>

<style lang="scss">
#studentCheck {
	.head {
		background-color: #ffffff;
		padding: 8px 20px;
		margin-top: 15px;
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
	}
	.main-item {
		background: #ffffff;
		margin-top: 15px;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
			color: #4e4e4e;
			border-bottom: 1px solid #fafafb;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 15px;
		height: 85vh;
		overflow: hidden;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.vue-circular-progress .circle__progress {
		stroke-linecap: square;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 20px;
		.el-radio {
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
	.el-dialog__footer {
		border-top: 1px solid #eeeeee;
		text-align: center;
	}
	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
